import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import {createTheme, StylesProvider, ThemeProvider} from "@material-ui/core/styles";
import App from "./host/App";


const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#02ffe2',
    },
    secondary: {
      main: '#f3ff43',
    },
  },

  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
      },
    },
  },
});

ReactDOM.render(
    <React.StrictMode>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App/>
        </ThemeProvider>
      </StylesProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();