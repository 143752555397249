import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import styles from "./FAQ.module.scss";
import {ExpandMore} from "@material-ui/icons";

interface FAQProps {
  id: string;
  expanded: boolean;
  question: string | JSX.Element;
  answer: string | JSX.Element;
  handleChange: (event: any, isExpanded: any) => void;
}

export default function FAQ(props: FAQProps) {
  return (
      <Accordion className={styles.panel} id={props.id} expanded={props.expanded} onChange={props.handleChange}>
        <AccordionSummary expandIcon={<ExpandMore/>} className={styles.question}>
          {props.question}
        </AccordionSummary>
        <AccordionDetails className={styles.answer}>
          {props.answer}
        </AccordionDetails>
      </Accordion>
  );
}
