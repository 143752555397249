import React, {useLayoutEffect, useState} from "react";
import styles from "./FAQPage.module.scss";
import {TopBar} from "../topbar/TopBar";
import FAQ from "./FAQ";
import Footer from "../home/Footer";
import {Divider} from "@material-ui/core";

function FAQPage() {
  const WHAT_IS_ALPACA = "what-is-alpaca";
  const CANDLE_ON_ALPACA = "candle-on-alpaca";
  const WHO_MANAGES_MONEY = "who-manages-money";
  const CUSTOMIZE_STRATEGY = "customize-strategy";
  const PAPER_TRADING = "paper-trading";
  const PAPER_TRADES = "paper-trades";
  const DATA_SECURITY = "data-security";
  const DATA_STORE= "data-store";

  const faqQuestions = [
    WHAT_IS_ALPACA,
    CANDLE_ON_ALPACA,
    WHO_MANAGES_MONEY,
    CUSTOMIZE_STRATEGY,
    PAPER_TRADING,
    PAPER_TRADES,
    DATA_SECURITY,
    DATA_STORE,
  ]

  const [expanded, setExpanded] = useState("");
  useLayoutEffect(() => {
    const anchor = window.location.hash.split('#')[1];
    if (anchor) {
      const accExists = faqQuestions.find(q => q === anchor)
      if (accExists) {
        setExpanded(anchor);
      }
    } else {
      setExpanded("");
    }
  }, [window.location.hash]);

  const handleQuestionChange = (panel: string) => (event: any, isExpanded: any) => {
    const hash = isExpanded ? panel : "";
    window.location.hash = hash;
    setExpanded(hash);
  };

  return (
      <div className={styles.root}>
        <TopBar/>
        <div className={styles.title}>Frequently Asked Questions</div>
        <FAQ id={WHAT_IS_ALPACA}
            expanded={expanded === WHAT_IS_ALPACA}
            handleChange={handleQuestionChange(WHAT_IS_ALPACA)}
            question="What is Alpaca?"
            answer={
              <div>
                <a className="cw-underline" href="https://alpaca.markets/">Alpaca</a> is an API first brokerage with
                zero
                commissions.
                Alpaca is registered with <a className="cw-underline"
                  href="https://www.sipc.org/list-of-members/A">SPIC</a>,
                which protects brokerage accounts up to $500,000 in securities, of which $250,000 can be cash.
              </div>
            }
        />

        <FAQ id={CANDLE_ON_ALPACA}
            expanded={expanded === CANDLE_ON_ALPACA}
            handleChange={handleQuestionChange(CANDLE_ON_ALPACA)}
            question="What can CandleX do with my Alpaca account?"
            answer={
              <div>
                <p>
                  To place automated trades on your behalf, we need your authorization to:
                  <ul>
                    <li>Place transactions in your account.</li>
                    <li>Access your account balance. Based on your account balance and your
                      allocation on a stock on CandleX, we would be able to calculate how many shares to buy and sell.</li>
                  </ul>
                </p>
                <p>
                  Your funds remain in your Alpaca account, CandleX is unable to withdraw any funds from it.
                </p>
              </div>
            }
        />

        <FAQ id={WHO_MANAGES_MONEY}
            expanded={expanded === WHO_MANAGES_MONEY}
            handleChange={handleQuestionChange(WHO_MANAGES_MONEY)}
            question="Does CandleX manage my money, or do I?"
            answer={
              <div>
                You manage your money. CandleX provides historical data to help you make informed decisions on your
                investment, and execute your decisions for you. You then come back from time to time to manage your
                portfolio, based on the actual performance of your investment.
              </div>
            }
        />

        <FAQ id={CUSTOMIZE_STRATEGY}
            expanded={expanded === CUSTOMIZE_STRATEGY}
            handleChange={handleQuestionChange(CUSTOMIZE_STRATEGY)}
            question="Can I customize strategies?"
            answer={
              <div>
                You can tweak the key parameters on any of our supported strategies. Additionally, you can tweak common
                parameters such as the take profit and stop loss percents on any strategy.
              </div>
            }
        />

        <FAQ id={PAPER_TRADING}
            expanded={expanded === PAPER_TRADING}
            handleChange={handleQuestionChange(PAPER_TRADING)}
            question="Is paper trading supported?"
            answer={
              <div>
                Yes. Paper trading broker is available as soon as you sign up CandleX. Deploy strategies to this broker,
                we'll automatically place buy/sell orders on our simulated engine, instead of placing actual orders at a
                brokerage.
              </div>
            }
        />

        <FAQ id={PAPER_TRADES}
            expanded={expanded === PAPER_TRADES}
            handleChange={handleQuestionChange(PAPER_TRADES)}
            question="Why are my paper trades not showing up in my Alpaca account?"
            answer={
              <div>
                Unlike live trades, paper trades are simulated in our own engine and not sent to Alpaca.
                Therefore they are only visible in CandleX.
              </div>
            }
        />

        <FAQ id={DATA_SECURITY}
            expanded={expanded === DATA_SECURITY}
            handleChange={handleQuestionChange(DATA_SECURITY)}
            question="How secure is my data?"
            answer={
              <div>
                CandleX enforces TLS encryption of all data transferred between you and our servers. We
                use <a className="cw-underline" href="https://oauth.net/2/access-tokens/">OAuth authentication</a> with TLS
                encryption for data transfer.
              </div>
            }
        />

        <FAQ id={DATA_STORE}
            expanded={expanded === DATA_STORE}
            handleChange={handleQuestionChange(DATA_STORE)}
            question="Where is my data stored?"
            answer="All CandleX servers and data reside in the United States."
        />

        <Divider className="mt-3 mb-5"/>
        <Footer/>
      </div>
  );
}

export default FAQPage;
