import React, {useEffect, useState} from "react";
import styles from "./PricingPage.module.scss";
import {signupUrl, TopBar} from "../topbar/TopBar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
import {PricingPageResponseCS} from "../generated/proto/consumerserver/pricing_page";
import axios from "axios";
import {Close} from "@material-ui/icons";
import {Divider} from "@material-ui/core";
import Footer from "../home/Footer";

/**
 * Similar UI to {@StartSubscriptionComponent}, consider adding to that component too if you make changes here.
 */
export function PricingPage() {
  const [annualBilling, setAnnualBilling] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageProto, setPageProto] = useState<PricingPageResponseCS>();

  useEffect(() => {
    const initiate = async () => {
      setPageProto(undefined);
      setLoading(true);
      setError(false);

      try {
        let result = await axios.get("/api/pricingPage");
        setPageProto(PricingPageResponseCS.fromJSON(result.data));
      } catch (e) {
        setError(true);
        console.log(`SubscriptionSignup Error: ${e}`);
      } finally {
        setLoading(false);
      }
    };

    // Run
    initiate().catch(console.error);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnnualBilling(event.target.checked);
  };

  function handleClick() {
    setAnnualBilling(!annualBilling);
  }

  let freeTier = pageProto?.freeTier;
  let premiumTier = pageProto?.premiumTier;

  return (
      <div className={styles.root}>
        <TopBar/>

        {pageProto !== undefined &&
            <>
              <div className={styles.title}>Pricing</div>
              <div className={styles.subtitle}>Automate your investing effortlessly</div>

              <Button className={`${styles.getStarted} candle-highlight`} size="large" href={signupUrl}>Get started
                now</Button>

              <div className={styles.billingPeriod}>
                <span onClick={handleClick}>Monthly</span>
                <Switch checked={annualBilling} onChange={handleChange} color="default"
                    inputProps={{"aria-label": "Toggles pricing period. When checked, period is annual, otherwise monthly"}}/>
                <span onClick={handleClick}>Annually <span className={styles.save16}>(save 16%)</span></span>
              </div>

              <div className={styles.compareBlock}>
                <div className={styles.plan}>
                  <div className={styles.planName}>Basic</div>
                  <div className={styles.price}>$0</div>
                  <div className={styles.priceDescription}>No credit card required</div>
                  <div className={styles.benefits}>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Unlimited backtesting
                    </div>
                    <div>
                      <Warning fontSize="small" className={styles.icon}/>
                      Limited live trading
                    </div>
                    <div>
                      <Warning fontSize="small" className={styles.icon}/>
                      {freeTier?.maxEquity} portfolio limit
                    </div>
                    <div>
                      <Warning fontSize="small" className={styles.icon}/>
                      {freeTier?.liveStrategyLimit} live strategies
                    </div>
                    <div>
                      <Warning fontSize="small" className={styles.icon}/>
                      {freeTier?.paperStrategyLimit} paper strategies
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Earnings call reminder
                    </div>
                    <div className={styles.unavailable}>
                      <Close fontSize="small" className={styles.icon}/>
                      Stock screener
                    </div>
                    <div className={styles.unavailable}>
                      <Close fontSize="small" className={styles.icon}/>
                      Exclusive strategies
                    </div>
                    <div className={styles.unavailable}>
                      <Close fontSize="small" className={styles.icon}/>
                      Auto tune strategies
                    </div>
                    <div className={styles.unavailable}>
                      <Close fontSize="small" className={styles.icon}/>
                      Performance insights
                    </div>
                  </div>
                </div>

                <div className={styles.plan}>
                  <div className={`${styles.planName} ${styles.premium}`}>CandleX</div>
                  <div className={styles.price}>
                    {annualBilling && <>$42</>}
                    {!annualBilling && <>$50</>}
                    <span className={styles.perMonth}> / month</span>
                  </div>
                  <div className={styles.priceDescription}>
                    Free 30-day trial.&nbsp;
                    {annualBilling && <>Billed $500 / year. Cancel anytime</>}
                    {!annualBilling && <>Cancel anytime</>}
                  </div>
                  <div className={styles.benefits}>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Unlimited backtesting
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Live trading
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      <span className="candle-text-highlight">No investment</span> limit
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      <span className="candle-text-highlight">Unlimited</span> live strategies
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      <span className="candle-text-highlight">Unlimited</span> paper strategies
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Earnings call reminder
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Stock <span className="candle-text-highlight">screener</span>
                    </div>
                    <div className={styles.unavailable}>
                      <Close fontSize="small" className={styles.icon}/>
                      Exclusive strategies
                    </div>
                    <div className={styles.unavailable}>
                      <Close fontSize="small" className={styles.icon}/>
                      Auto tune strategies
                    </div>
                    <div className={styles.unavailable}>
                      <Close fontSize="small" className={styles.icon}/>
                      Performance insights
                    </div>
                  </div>
                </div>

                <div className={styles.plan}>
                  <div className={`${styles.planName} ${styles.plus}`}>Candle Plus</div>
                  <div className={styles.price}>
                    {annualBilling && <>$83</>}
                    {!annualBilling && <>$100</>}
                    <span className={styles.perMonth}> / month</span>
                  </div>
                  <div className={styles.priceDescription}>
                    Free 30-day trial.&nbsp;
                    {annualBilling && <>Billed $1000 / year. Cancel anytime</>}
                    {!annualBilling && <>Cancel anytime</>}
                  </div>
                  <div className={styles.benefits}>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Unlimited backtesting
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Live trading
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      <span className="candle-text-highlight">No investment</span> limit
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      <span className="candle-text-highlight">Unlimited</span> live strategies
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      <span className="candle-text-highlight">Unlimited</span> paper strategies
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Earnings call reminder
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Stock <span className="candle-text-highlight">screener</span>
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      <span className="candle-text-highlight">Exclusive</span> strategies
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      <span className="candle-text-highlight">Auto tune</span> strategies
                    </div>
                    <div>
                      <Check fontSize="small" className={styles.icon}/>
                      Performance <span className="candle-text-highlight">insights</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.getStartedBottom}>
                <Button className={`candle-highlight`} size="large" href={signupUrl}>Get started</Button>
              </div>
            </>
        }

        {loading &&
        <div className={styles.loading}>
          <CircularProgress/>
        </div>
        }

        {error && <div>Something went wrong 🙁</div>}

        <Divider className="my-5"/>
        <Footer/>
      </div>
  );
}
