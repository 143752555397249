/* eslint-disable */

export const protobufPackage = "xyz.candle.consumerserver";

export interface PricingPageResponseCS {
  freeTier: MembershipTierCS | undefined;
  premiumTier: MembershipTierCS | undefined;
}

export interface MembershipTierCS {
  paperStrategyLimit?: number | undefined;
  liveStrategyLimit?: number | undefined;
  maxEquity: string;
}

function createBasePricingPageResponseCS(): PricingPageResponseCS {
  return { freeTier: undefined, premiumTier: undefined };
}

export const PricingPageResponseCS = {
  fromJSON(object: any): PricingPageResponseCS {
    return {
      freeTier: isSet(object.freeTier) ? MembershipTierCS.fromJSON(object.freeTier) : undefined,
      premiumTier: isSet(object.premiumTier) ? MembershipTierCS.fromJSON(object.premiumTier) : undefined,
    };
  },

  toJSON(message: PricingPageResponseCS): unknown {
    const obj: any = {};
    message.freeTier !== undefined &&
      (obj.freeTier = message.freeTier ? MembershipTierCS.toJSON(message.freeTier) : undefined);
    message.premiumTier !== undefined &&
      (obj.premiumTier = message.premiumTier ? MembershipTierCS.toJSON(message.premiumTier) : undefined);
    return obj;
  },
};

function createBaseMembershipTierCS(): MembershipTierCS {
  return { paperStrategyLimit: undefined, liveStrategyLimit: undefined, maxEquity: "" };
}

export const MembershipTierCS = {
  fromJSON(object: any): MembershipTierCS {
    return {
      paperStrategyLimit: isSet(object.paperStrategyLimit) ? Number(object.paperStrategyLimit) : undefined,
      liveStrategyLimit: isSet(object.liveStrategyLimit) ? Number(object.liveStrategyLimit) : undefined,
      maxEquity: isSet(object.maxEquity) ? String(object.maxEquity) : "",
    };
  },

  toJSON(message: MembershipTierCS): unknown {
    const obj: any = {};
    message.paperStrategyLimit !== undefined && (obj.paperStrategyLimit = Math.round(message.paperStrategyLimit));
    message.liveStrategyLimit !== undefined && (obj.liveStrategyLimit = Math.round(message.liveStrategyLimit));
    message.maxEquity !== undefined && (obj.maxEquity = message.maxEquity);
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
