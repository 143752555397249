import React from "react";
import styles from "./PrivacyPage.module.scss";

function PrivacyPage() {
  return (
      <div className={styles.page}>
        <h1 className={styles.title}>
          Candle Exchange Privacy Policy
        </h1>

        <p className={styles.paragraph}>
          <b>Last Updated:</b> April 18, 2022
        </p>

        <p className={styles.paragraph}>
          Candle Exchange, Inc. (“Candle Exchange”, “us”, “we”, “our”) has created this Privacy Policy (“Policy”) to
          demonstrate our firm commitment to privacy. Due to the sensitive nature of the information we collect from our
          users, including name, address and phone numbers, we believe it is important to satisfy strict privacy
          requirements.
        </p>

        <h2 className={styles.header2}>Overview</h2>

        <p className={styles.paragraph}>
          Candle Exchange is committed to maintaining the privacy and confidentiality of our customers’ personal
          information (“you”, “your”). In the course of offering and providing our services, we collect and maintain
          certain personal information about you. This information can come from information you provide on applications
          or other forms, through your inquiries by mail, e-mail, or telephone, or by other means. This policy explains
          what personal information we collect and how we use and share such personal information. It also explains what
          you can do to limit the use and sharing of your personal information. We do not disclose personal information
          about our customers to non-affiliated third parties, except as described below.
        </p>

        <h2 className={styles.header2}>Online Privacy</h2>

        <p className={styles.paragraph}>
          Candle Exchange may periodically collect information from you through automated means. When you browse our
          site, use our services, or read our emails or newsletters, we may use “cookies” or other online tracking
          mechanisms (including pixel tags, web beacons, clear GIFs and device fingerprints) in order to provide better
          service, facilitate your use of the site or services, store online preferences, track usage of the site or
          services, tailor the marking of business services, or enhance security. Candle Exchange does not store your
          login credentials.
        </p>

        <p className={styles.paragraph}>
          Some of our cookies are used for web analytics and intelligence about our site. We may also use web beacons or
          other technologies for similar purposes and we may include these in marketing email messages or our
          newsletters in order to determine whether messages have been opened and links clicked on. Web beacons do not
          place information on your device but may work in conjunction with cookies to monitor online activity.
        </p>

        <p className={styles.paragraph}>
          By using our site or applications you agree that we can place cookies on your device and use other online
          tracking mechanisms as explained in this privacy notice. You may be able to control your acceptance of cookies
          and similar technologies on a website, and most devices and browsers offer their own privacy settings for
          cookies. You will need to manage your cookie settings for each device and browser you use. If you do not
          accept cookies, you may experience some inconvenience in your use of some sites and online products. For
          example, a website may not be able to recognize your device and you may need to answer challenge questions or
          provide additional information each time you log on.
        </p>

        <p className={styles.paragraph}>
          To help us protect against fraud, provide our services to you, and improve our services, we may collect
          location data and your IP addresses that are used in conjunction with our services.
        </p>

        <p className={styles.paragraph}>
          We also may obtain information on the device you use, including the Universally Unique Identifier, advertising
          identifier (“IDFA” or “AdID”), operating system and version, wireless carrier, and network type. This
          information is used to identify customer device issues and for marketing purposes.
        </p>

        <p className={styles.paragraph}>
          If you subscribe to any of our communications, we will use your name and email address to send communications
          to you. You can unsubscribe at any time following the instructions at the bottom of these emails or you can
          contact us at the information below. We also may collect information from you derived or resulting from
          voluntary surveys.
        </p>

        <h2 className={styles.header2}>Information You Provide</h2>

        <p className={styles.paragraph}>
          Candle Exchange will only collect your email address in order to create an account to use our Services, but we
          will also collect additional personal information, including your name, phone number, broker account number,
          and trade-related data pursuant to your use of the Services.
        </p>

        <p className={styles.paragraph}>
          In addition to data you expressly provide to us, we may also utilize Google Analytics or other analytics tools
          to collect information related to the pages of our website that you visit, how long you stay on a particular
          page, and how you use our Services. Any analytics data we collect will not contain any of your personally
          identifiable information and will be strictly limited to our own internal use.
        </p>

        <h2 className={styles.header2}>Protecting information</h2>

        <p className={styles.paragraph}>
          With regard to our internal security procedures, we restrict access to your personal and account information
          to those employees who “need to know” the information in order to provide products and services to you. We
          maintain reasonable physical, administrative, and technical safeguards to protect your personal information.
          We conduct regular internal audits of our business practices and procedures in order to protect your personal
          information.
        </p>

        <p className={styles.paragraph}>
          We may also disclose your personal information as required by law, such as to comply with a subpoena, or
          similar legal process and when we believe in good faith that disclosure is necessary to protect our rights,
          protect your safety or the safety of others, investigate fraud, or respond to a government request.
        </p>

        <p className={styles.paragraph}>
          We are not responsible for inaccuracies in data provided by third parties, such as credit reporting bureaus.
          You may review and correct your data by contacting us at support@candle.xyz.
        </p>

        <h2 className={styles.header2}>Retention of Your Information</h2>

        <p className={styles.paragraph}>
          We will retain your personal information for as long as it serves the purposes(s) for which it was initially
          collected as stated in this Privacy Policy, or subsequently authorized. We will also retain and use personal
          information as necessary to comply with our legal obligations, resolve disputes, or enforce our agreements. If
          you are no longer a customer, we will not destroy your information unless required or permitted by law. We
          will continue to treat your personal information in accordance with this privacy policy and applicable laws.
        </p>

        <p className={styles.paragraph}>
          Information connected to you that is no longer necessary and relevant to provide our services may be
          aggregated with other non-personal data to provide insights which are commercially valuable to Candle
          Exchange, such as statistics of the use of Candle Exchange Services.
        </p>

        <h2 className={styles.header2}>Children’s Privacy</h2>

        <p className={styles.paragraph}>
          We do not knowingly collect or solicit any information from anyone under the age of 18 on our services. In the
          event that we learn that we have inadvertently collected personal information from a child under age 18, we
          will take reasonable steps to delete this information. If you believe that we might have any information from
          a child under 18, please contact us at support@candle.xyz.
        </p>

        <h2 className={styles.header2}>California Privacy Rights</h2>

        <p className={styles.paragraph}>
          If you are a California resident, California law requires us to provide you with some additional information
          regarding how we collect, use and share your “personal information” (as defined in the California Consumer
          Privacy Act (“CCPA”)), other than the personal information we collect in connection with providing financial
          products and services.
        </p>

        <p className={styles.paragraph}>
          Depending on how you interact with our website and services, we may collect the following categories of
          personal information about you, in addition to the information we collect in connection with providing
          financial information and services:
        </p>

        <p className={styles.paragraph}>
          Identifiers, such as your name and contact information (if provided), IP address, and device identifiers.
        </p>

        <p className={styles.paragraph}>
          Internet usage information, such as browsing history, access history, and use and interaction with our
          website.
        </p>

        <p className={styles.paragraph}>
          This information is used and disclosed for the purposes and to the categories of third parties described in
          the main privacy policy, above.
        </p>

        <p className={styles.paragraph}>
          Your California Privacy Rights: If you are a California resident, you may have certain rights. California law
          may permit you to request that we:
        </p>

        <ul>
          <li className={styles.li}>
            Provide you the categories of personal information we have collected or disclosed about you in the last
            twelve months; the categories of sources of such information; the business or commercial purpose for
            collecting or distributing your personal information; and the categories of third parties with whom we
            shared personal information.
          </li>
          <li className={styles.li}>Provide access to and/or a copy of certain information we hold about you.</li>
          <li className={styles.li}>Delete certain information we may have about you.</li>
        </ul>

        <p className={styles.paragraph}>
          We do not sell your personal information to third parties. We do allow third parties to collect personal
          information through our service and share personal information with third parties for the business purposes
          described in this Privacy Policy, including without limitation advertising and marketing on our service and
          elsewhere based on users’ online activities over time and across different sites, services, and devices.
        </p>

        <p className={styles.paragraph}>
          California law permits consumers to request, once per year, that we disclose the identity of any third parties
          with whom we have shared personal information for the third parties’ direct marketing purposes within the
          previous calendar year, along with the type of personal information disclosed. Please contact
          support@candle.xyz for such requests.
        </p>

        <h2 className={styles.header2}>Updates to this Policy</h2>

        <p className={styles.paragraph}>
          As required by federal law, we will notify you of our privacy policy annually. We reserve the right to modify
          this policy at any time. If we do change it, we will revise the “Effective Date” above and post the new
          policy. To stay informed of our privacy practices, we recommend you review this Policy on a regular basis as
          you continue to use our Services.
        </p>

        <h2 className={styles.header2}>Opt-Out</h2>

        <p className={styles.paragraph}>
          Candle Exchange provides users the opportunity to opt-out of receiving communications from us at the point
          where we request information about you. You can send an email to support@candle.xyz and request that we remove
          your information from our database, to not receive future communications, or to no longer receive our
          Services.
        </p>

        <h2 className={styles.header2}>Other important information</h2>

        <h3 className={styles.header3}>State and International Laws</h3>

        <p className={styles.paragraph}>
          For Vermont Residents: In accordance with Vermont Laws, we will not share information we collect about Vermont
          residents with companies who are not affiliates, except as permitted by law, such as with your consent or to
          service your accounts. We will not share information about your creditworthiness with our affiliates without
          your authorization or consent, but we may share information about our transactions or experiences with you
          with our affiliates without your consent.
        </p>

        <p className={styles.paragraph}>
          For Nevada Residents: Nevada law requires us to disclose that you may request to be placed on Candle
          Exchange’s internal “do not call” list at any time by emailing us at support@candle.xyz, and that we are
          providing this notice to you pursuant to state law, and that you may obtain further information by contacting
          the Nevada Attorney General, 555 E. Washington Ave., Suite 3900, Las Vegas, NV 89101; phone 702-486-3132;
          email BCPINFO@ag.state.nv.us.
        </p>

        <p className={styles.paragraph}>
          For California Residents: In accordance with California law, we will not share information we collect about
          you with nonaffiliates, except as allowed by law. For example, we may share information with your consent or
          to service your accounts. Among our affiliates, we will limit information sharing to the extent required by
          California law.
        </p>

        <h2 className={styles.header2}>Contact Us</h2>

        <p className={styles.paragraph}>
          For questions about our policy, or for additional copies of this notice, please email us at
          support@candle.xyz.
        </p>
      </div>
  );
}

export default PrivacyPage;
