import React from "react";
import styles from "./TermsPage.module.scss";

function TermsPage() {
  return (
      <div className={styles.page}>
        <h1 className={styles.title}>
          Candle Exchange Terms of Service
        </h1>

        <p className={styles.paragraph}>
          <b>Last Updated:</b> April 14, 2022
        </p>

        <p className={styles.paragraph}>
          PLEASE READ THIS TERMS OF SERVICE AGREEMENT (THE “<b>TERMS OF SERVICE</b>”) CAREFULLY. THIS WEBSITE AND ITS
          SUBDOMAINS (COLLECTIVELY, THE “<b>WEBSITE</b>”), THE INFORMATION ON THE WEBSITE, AND THE SERVICES AND
          RESOURCES AVAILABLE OR ENABLED VIA THE WEBSITE (EACH A “<b>SERVICE</b>” AND COLLECTIVELY WITH THE WEBSITE, THE
          “<b>SERVICES</b>”), ARE CONTROLLED BY CANDLE EXCHANGE, INC. (“<b>COMPANY</b>”). THESE TERMS OF SERVICE ALONG
          WITH ALL SUPPLEMENTAL TERMS THAT MAY BE PRESENTED TO YOU FOR YOUR REVIEW AND ACCEPTANCE (COLLECTIVELY, THE
          “<b>AGREEMENT</b>”), GOVERN YOUR ACCESS TO AND USE OF THE SERVICES. BY CLICKING ON THE “Accept Terms of
          Service” BUTTON, COMPLETING THE REGISTRATION PROCESS, BROWSING THE WEBSITE, OR ACCESSING OR USING ANY OF THE
          SERVICES, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE AGREEMENT, (2) YOU
          ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH COMPANY, AND (3) YOU HAVE THE AUTHORITY TO ENTER INTO THE
          AGREEMENT PERSONALLY OR ON BEHALF OF THE LEGAL ENTITY IDENTIFIED DURING THE ACCOUNT REGISTRATION PROCESS, AND
          TO BIND THAT LEGAL ENTITY TO THE AGREEMENT. THE TERM “<b>YOU</b>” REFERS TO THE INDIVIDUAL OR SUCH LEGAL
          ENTITY, AS APPLICABLE. <b>IF YOU, OR IF APPLICABLE, SUCH LEGAL ENTITY, DO NOT AGREE TO BE BOUND BY THE
          AGREEMENT, YOU, AND IF APPLICABLE, SUCH LEGAL ENTITY, MAY NOT ACCESS OR USE ANY OF THE SERVICES.</b>
        </p>

        <p className={styles.paragraph}>
          IF YOU SUBSCRIBE TO THE SERVICES FOR A TERM (THE “<b>INITIAL TERM</b>”), THE AGREEMENT WILL BE AUTOMATICALLY
          RENEWED FOR ADDITIONAL PERIODS OF THE SAME DURATION AS THE INITIAL TERM AT COMPANY’S THEN-CURRENT FEE FOR SUCH
          SERVICES UNLESS YOU TERMINATE YOUR SUBSCRIPTION AND/OR YOUR ACCOUNT IN ACCORDANCE WITH SECTION 7.7 (AUTOMATIC
          RENEWAL) BELOW.
        </p>

        <p className={styles.paragraph}>
          PLEASE BE AWARE THAT SECTION 14 (DISPUTE RESOLUTION) OF THE AGREEMENT BELOW CONTAINS PROVISIONS GOVERNING HOW
          ANY DISPUTES BETWEEN US WILL BE RESOLVED. IN PARTICULAR, IT CONTAINS AN ARBITRATION AGREEMENT WHICH WILL, WITH
          LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION. UNLESS YOU
          OPT OUT OF THE ARBITRATION AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST
          US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR
          PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO PURSUE CLAIMS AND SEEK RELIEF IN A COURT OF LAW AND TO HAVE
          A JURY TRIAL.
        </p>

        <p className={styles.paragraph}>
          PLEASE BE AWARE THAT SECTION 1.4 (COMPANY COMMUNICATIONS) OF THE AGREEMENT BELOW CONTAINS YOUR OPT-IN CONSENT
          TO RECEIVE COMMUNICATIONS FROM US, INCLUDING, AS APPLICABLE, VIA E-MAIL, TEXT MESSAGE, CALLS AND PUSH
          NOTIFICATION.
        </p>

        <p className={styles.paragraph}>
          PLEASE NOTE THAT THE AGREEMENT IS SUBJECT TO CHANGE BY COMPANY IN ITS SOLE DISCRETION AT ANY TIME. WHEN
          CHANGES ARE MADE, COMPANY WILL MAKE A COPY OF THE UPDATED AGREEMENT AVAILABLE AT THE WEBSITE AND UPDATE THE
          “LAST UPDATED DATE” AT THE TOP OF THESE TERMS OF SERVICE. IF WE MAKE ANY MATERIAL CHANGES TO THE AGREEMENT, WE
          WILL PROVIDE NOTICE OF SUCH MATERIAL CHANGES ON THE WEBSITE AND ATTEMPT TO NOTIFY YOU BY SENDING AN E-MAIL TO
          THE E-MAIL ADDRESS PROVIDED IN YOUR ACCOUNT REGISTRATION. ANY CHANGES TO THE AGREEMENT WILL BE EFFECTIVE
          IMMEDIATELY FOR NEW USERS OF THE SERVICES AND WILL BE EFFECTIVE FOR EXISTING REGISTERED USERS UPON THE EARLIER
          OF (A) THIRTY (30) DAYS AFTER THE “LAST UPDATED DATE” AT THE TOP OF THESE TERMS OF SERVICE, OR (B) YOUR
          CONSENT TO AND ACCEPTANCE OF THE UPDATED AGREEMENT IF COMPANY PROVIDES A MECHANISM FOR YOUR IMMEDIATE
          ACCEPTANCE IN A SPECIFIED MANNER (SUCH AS A CLICK-THROUGH ACCEPTANCE), WHICH COMPANY MAY REQUIRE BEFORE
          FURTHER USE OF THE SERVICES IS PERMITTED. IF YOU DO NOT AGREE TO THE UPDATED AGREEMENT, YOU MUST STOP USING
          ALL SERVICES UPON THE EFFECTIVE DATE OF THE UPDATED AGREEMENT. OTHERWISE, YOUR CONTINUED USE OF ANY OF THE
          SERVICES AFTER THE EFFECTIVE DATE OF THE UPDATED AGREEMENT CONSTITUTES YOUR ACCEPTANCE OF THE UPDATED
          AGREEMENT. PLEASE REGULARLY CHECK THE WEBSITE TO VIEW THE THEN-CURRENT AGREEMENT. YOU AGREE THAT COMPANY’S
          CONTINUED PROVISION OF THE SERVICES IS ADEQUATE CONSIDERATION FOR THE CHANGES IN THE UPDATED AGREEMENT.
        </p>

        <h2 className={styles.header2}>1. USE OF THE SERVICES</h2>

        <p className={styles.paragraph}>
          The Services, and the information and content available on them, are protected by applicable intellectual
          property laws.
        </p>

        <h3 className={styles.header3}>1.1. Company Software</h3>

        <p className={styles.paragraph}>
          Use of any software and associated documentation that is made available via the Services (“<b>Software</b>”)
          is governed by the Agreement. Subject to your compliance with the Agreement, Company grants you a
          non-assignable, non-transferable, non-sublicensable, revocable, non-exclusive license to use the Software for
          the sole purpose of enabling you to use the Services in the manner permitted by the Agreement.
        </p>

        <h3 className={styles.header3}>1.2. Updates</h3>

        <p className={styles.paragraph}>
          You understand that the Services are evolving and agree that Company may update the Services with or without
          notifying you.
        </p>

        <h3 className={styles.header3}>1.3. Certain Restrictions</h3>

        <p className={styles.paragraph}>
          The rights granted to you in the Agreement are subject to the following restrictions: (a) you shall not
          license, sell, rent, lease, transfer, assign, reproduce, distribute, host or otherwise commercially exploit
          any of the Services; (b) you shall not frame or utilize framing techniques to enclose any trademark, logo, or
          other parts of the Services (including images, text, page layout or form); (c) you shall not use any metatags
          or other “hidden text” using Company’s name or trademarks; (d) you shall not modify, translate, adapt, merge,
          make derivative works of, disassemble, decompile, reverse compile or reverse engineer any part of the Services
          except to the extent the foregoing restrictions are expressly prohibited by applicable law; (e) you shall not
          use any manual or automated software, devices or other processes (including but not limited to spiders,
          robots, scrapers, crawlers, avatars, data mining tools or the like) to “scrape” or download data from any web
          pages contained in the Services (except that we grant the operators of public search engines revocable
          permission to use spiders to copy materials from the Website for the sole purpose of and solely to the extent
          necessary for creating publicly available searchable indices of the materials, but not caches or archives of
          such materials); and (f) except as expressly stated herein, no part of the Services may be copied, reproduced,
          distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Any future
          release, update or other addition to the Services shall be subject to the Agreement. Company, its suppliers
          and service providers reserve all rights not granted in the Agreement.
        </p>

        <h3 className={styles.header3}>1.4. Company Communications</h3>

        <p className={styles.paragraph}>
          By entering into the Agreement or using the Services, you agree to receive communications from us, which may
          include e-mail, text message, calls, and push notifications. You agree that texts, calls or prerecorded
          messages may be generated by automatic telephone dialing systems. Communications from us and our affiliated
          companies may include but are not limited to: operational communications concerning your Account or the use of
          the Services, updates concerning new and existing features on the Services, communications concerning
          promotions run by us or our third-party partners, and news concerning the Company and industry developments.
          Standard text messaging charges applied by your cell phone carrier will apply to text messages that we send.
          IF YOU WISH TO OPT OUT OF PROMOTIONAL EMAILS, YOU CAN UNSUBSCRIBE FROM OUR PROMOTIONAL EMAIL LIST BY FOLLOWING
          THE UNSUBSCRIBE OPTIONS IN THE PROMOTIONAL EMAIL ITSELF.
        </p>

        <h3 className={styles.header3}>1.5. Privacy</h3>

        <p className={styles.paragraph}>
          Your use of the Services is also governed by our Privacy Policy. Please review our Privacy Policy at
          https://candle.xyz/privacy-policy.
        </p>

        <h2 className={styles.header2}>2. REGISTRATION</h2>

        <h3 className={styles.header3}>2.1. Registering Your Account</h3>

        <p className={styles.paragraph}>
          In order to access the Services you will be required to become a Registered User. For purposes of the
          Agreement, a “<b>Registered User</b>” is a user who has registered an account with Company through the
          Services (“<b>Account</b>”).
        </p>

        <h3 className={styles.header3}>2.2. Registration Data</h3>

        <p className={styles.paragraph}>
          In registering an Account, you agree to (a) provide true, accurate, current and complete information about
          yourself as prompted by the registration form (the “<b>Registration Data</b>”); and (b) maintain and promptly
          update the Registration Data to keep it true, accurate, current and complete. You represent that you are (i)
          at least eighteen (18) years old; (ii) of legal age to form a binding contract; and (iii) not a person barred
          from using the Services under the laws of the United States, your place of residence or any other applicable
          jurisdiction. You are responsible for all activities that occur under your Account. You agree to monitor your
          Account to restrict use by any other persons and accept full responsibility for any such unauthorized use. You
          may not share your Account login or password with anyone, and you agree to (y) notify Company immediately of
          any unauthorized use of your password or any other breach of security; and (z) exit from your Account at the
          end of each session. If you provide any information that is untrue, inaccurate, not current or incomplete, or
          Company has reasonable grounds to suspect that any information you provide is untrue, inaccurate, not current
          or incomplete, Company has the right to suspend or terminate your Account. You agree not to create an Account
          using a false identity or information, or on behalf of someone other than yourself. Company reserves the right
          to remove or reclaim any usernames at any time and for any reason, including but not limited to, claims by a
          third party that a username violates the third party’s rights.
        </p>

        <h3 className={styles.header3}>2.3. Your Account</h3>

        <p className={styles.paragraph}>
          Notwithstanding anything to the contrary herein, you acknowledge and agree that you have no ownership or other
          property interest in your Account, and that all rights in and to your Account are owned by Company.
        </p>

        <h3 className={styles.header3}>2.4. Necessary Equipment and Software</h3>

        <p className={styles.paragraph}>
          You must provide all equipment and software necessary to connect to the Services. You are solely responsible
          for any fees, including Internet connection fees, that you incur when accessing the Services.
        </p>

        <h2 className={styles.header2}>3. RESPONSIBILITY FOR USER CONTENT</h2>

        <h3 className={styles.header3}>3.1. User Content</h3>

        <p className={styles.paragraph}>
          You acknowledge that all Content is the sole responsibility of the party from whom such Content originated.
          This means that you, and not Company, are entirely responsible for all Content that you upload, post, e-mail,
          transmit or otherwise make available through the Services (“<b>Your Content</b>”), and that you and other
          Registered Users of the Services, and not Company, are similarly responsible for all Content that you and they
          make available through the Services (“<b>User Content</b>”). Company has no responsibility or liability for
          the accuracy or legality of any User Content, including Your Content.
        </p>

        <h3 className={styles.header3}>3.2. No Obligation to Pre-Screen User Content</h3>

        <p className={styles.paragraph}>
          You acknowledge that Company has no obligation to pre-screen User Content, although Company reserves the right
          in its sole discretion to pre-screen, refuse or remove any User Content. By entering into the Agreement, you
          hereby provide your irrevocable consent to Company’s monitoring of Your Content.
        </p>

        <h3 className={styles.header3}>3.3. Storage</h3>

        <p className={styles.paragraph}>
          You agree that Company retains the right to create reasonable limits on Company’s use and storage of User
          Content, including Your Content, such as limits on file size, storage space, processing capacity, and similar
          limits as determined by Company in its sole discretion.
        </p>

        <h2 className={styles.header2}>4. OWNERSHIP</h2>

        <h3 className={styles.header3}>4.1. Services</h3>

        <p className={styles.paragraph}>
          Except with respect to Your Content and other User Content, you agree that Company and its suppliers own all
          rights, title and interest in the Services (including all Software). You agree not to remove, alter or obscure
          any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying any
          Services.
        </p>

        <h3 className={styles.header3}>4.2. Trademarks</h3>

        <p className={styles.paragraph}>
          Company’s name and all related stylizations, graphics, logos, service marks and trade names used on or in
          connection with any Services are the trademarks of Company and may not be used without permission in
          connection with your, or any third-party, products or services. Third party trademarks, service marks and
          trade names that may appear on or in the Services are the property of their respective owners.
        </p>

        <h3 className={styles.header3}>4.3. Your Content</h3>

        <p className={styles.paragraph}>
          Company does not claim ownership of Your Content. However, when you post or publish Your Content on or in any
          Services, you represent that you own or have all necessary rights to post or publish Your Content on or in the
          Services.
        </p>

        <h3 className={styles.header3}>4.4. License to Your Content</h3>

        <p className={styles.paragraph}>
          You grant Company a fully paid, royalty-free, worldwide, non-exclusive right and license to use, reproduce,
          modify, adapt, publicly perform, and publicly display Your Content (in whole or in part) for the purposes of
          operating and providing the Services to you. Please remember that other Registered Users may search for, see,
          use, modify and reproduce any of Your Content that you submit to any “public” area of the Services. You agree
          that you, not Company, are responsible for all of Your Content.
        </p>

        <h3 className={styles.header3}>4.5. Aggregate Data</h3>

        <p className={styles.paragraph}>
          Notwithstanding anything to the contrary herein, you agree that Company: (a) may create, compile, analyze, and
          run analytics on aggregated and de-identified information derived from Your Content, how you use the Services,
          and/or the performance of the Services (“<b>Aggregate Data</b>”); (b) may combine such information with that
          of other users of the Services to create Aggregate Data; and (c) has the exclusive ownership of all Aggregate
          Data and the right to use Aggregate Data for any lawful purposes, including for the improvement of the
          Services and the development of new products and services, so long as Company does not use or distribute any
          Aggregate Data in a manner that identifies you, Your Content, or any of your users of the Services.
        </p>

        <h3 className={styles.header3}>4.6. Feedback</h3>

        <p className={styles.paragraph}>
          You agree that submission of any ideas, suggestions, documents, and/or proposals to Company regarding any of
          the Services (“<b>Feedback</b>”) is at your own risk and that Company has no obligations (including without
          limitation obligations of confidentiality) with respect to such Feedback. You represent and warrant that you
          have all rights necessary to submit the Feedback. You hereby grant to Company a fully paid, royalty-free,
          perpetual, irrevocable, worldwide, and non-exclusive right and license to use, reproduce, perform, display,
          distribute, adapt, modify, re-format, create derivative works of, and otherwise commercially or
          non-commercially exploit in any manner, any and all Feedback, and to sublicense the foregoing rights, in
          connection with the operation and maintenance of the Services and/or Company’s business.
        </p>

        <h2 className={styles.header2}>5. USER CONDUCT</h2>

        <p className={styles.paragraph}>
          As a condition of use, you agree not to use any of the Services for any purpose that is prohibited by this
          Agreement or by applicable law. You shall not (and shall not permit any third party) either (a) take any
          action or (b) make available any Content on or through the Services that: (i) infringes, misappropriates or
          otherwise violates any intellectual property right, right of publicity, right of privacy or other right of any
          person or entity; (ii) is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive,
          fraudulent, invasive of another’s privacy, tortious, obscene, offensive, or profane; (iii) constitutes
          unauthorized or unsolicited advertising, junk or bulk e-mail; (iv) involves commercial activities and/or
          sales, such as contests, sweepstakes, barter, advertising, or pyramid schemes without Company’s prior written
          consent; impersonates any person or entity, including any employee or representative of Company; interferes
          with or attempt to interfere with the proper functioning of the Services or uses the Services in any way not
          expressly permitted by the Agreement; or (vii) attempts to engage in or engage in, any potentially harmful
          acts that are directed against the Services, including but not limited to violating or attempting to violate
          any security features of the Services, introducing viruses, worms, or similar harmful code into the Services,
          or interfering or attempting to interfere with use of the Services by any other user, host or network,
          including by means of overloading, “flooding,” “spamming,” “mail bombing,” or “crashing” Company Properties.
        </p>

        <h2 className={styles.header2}>6. INTERACTIONS WITH OTHER USERS</h2>

        <h3 className={styles.header3}>6.1. User Responsibility</h3>

        <p className={styles.paragraph}>
          You are solely responsible for your interactions with other Registered Users and any other parties with whom
          you interact; provided, however, that Company reserves the right, but has no obligation, to intercede in such
          disputes. You agree that Company will not be responsible for any liability incurred as the result of such
          interactions.
        </p>

        <h3 className={styles.header3}>6.2. Content Provided by Other Users</h3>

        <p className={styles.paragraph}>
          The Services may contain User Content provided by other Registered Users. Company is not responsible for and
          does not control User Content. Company has no obligation to review or monitor, and does not approve, endorse
          or make any representations or warranties with respect to, User Content. You use all User Content and interact
          with other Registered Users at your own risk.
        </p>

        <h2 className={styles.header2}>7. FEES AND PURCHASE TERMS</h2>

        <h3 className={styles.header3}>7.1. General Purpose of Agreement: Sale of Service, not Software</h3>

        <p className={styles.paragraph}>
          The purpose of the Agreement is for you to secure access to the Services. In no way are the fees paid by you
          under the Agreement considered payment for the sale, license, or use of Company’s Software, and, furthermore,
          any use of Company’s Software by you in furtherance of the Agreement will be considered merely in support of
          the purpose of the Agreement.
        </p>

        <h3 className={styles.header3}>7.2. Payment</h3>

        <p className={styles.paragraph}>
          You agree to pay all fees or charges to your Account in accordance with the fees, charges and billing terms in
          effect at the time a fee or charge is due and payable in accordance with the Services. You must provide
          Company with a valid credit card (Visa, MasterCard, or any other issuer accepted by us) (each, a “<b>Payment
          Provider</b>”) as a condition to signing up for the Services. Your Payment Provider agreement governs your use
          of the designated credit card or PayPal account, and you must refer to that agreement, not this Agreement, to
          determine your rights and liabilities. By providing Company with your credit card number or PayPal account and
          associated payment information, you agree that Company is authorized to immediately invoice your Account for
          all fees and charges as they become due and payable and that no additional notice or consent is required. You
          agree to immediately notify Company of any change in your billing address or the credit card or PayPal account
          used for payment hereunder. Company reserves the right at any time to change its prices and billing methods,
          either immediately upon posting on the Services or by e-mail delivery to you.
        </p>

        <h3 className={styles.header3}>7.3. Third Party Payment Processor</h3>

        <p className={styles.paragraph}>
          The Company uses Stripe, Inc. and its affiliates as the third party service provider for payment services
          (e.g., card acceptance, merchant settlement, and related services). By using the Services, you agree to be
          bound by Stripe’s Privacy Policy (currently accessible at https://stripe.com/us/privacy) and its Terms of
          Service (currently accessible at https://stripe.com/us/terms) and hereby consent and authorize the Company and
          Stripe to share any information and payment instructions you provide with the applicable Payment Provider(s)
          to the minimum extent required to complete your transactions.
        </p>

        <h3 className={styles.header3}>7.4. Service Subscription Fees</h3>

        <p className={styles.paragraph}>
          You will be responsible for payment of the applicable subscription fee for the Services (each, a “<b>Service
          Subscription Fee</b>”) at the time you create your Account and select your payment package, or when you choose
          to upgrade from a free account to a paid version (each, a “<b>Service Commencement Date</b>”). Except as set
          forth in the Agreement, all fees for the Services are non-refundable. No contract will exist between you and
          Company for the Services until Company accepts your order by a confirmatory e-mail, SMS/MMS message, or other
          appropriate means of communication.
        </p>

        <h3 className={styles.header3}>7.5. Taxes</h3>

        <p className={styles.paragraph}>
          The payments required under Section 7.4 (Service Subscription Fees) of this Agreement do not include any Sales
          Tax that may be due in connection with the services provided under the Agreement. If Company determines it has
          a legal obligation to collect a Sales Tax from you in connection with the Agreement, Company may collect such
          Sales Tax in addition to the payments required under Section 7.4 (Service Subscription Fees) of the Agreement.
          If any services, or payments for any services, under the Agreement are subject to any Sales Tax in any
          jurisdiction and you have not remitted the applicable Sales Tax to Company, you will be responsible for the
          payment of such Sales Tax and any related penalties or interest to the relevant tax authority, and you will
          indemnify Company for any liability or expense Company may incur in connection with such Sales Taxes. Upon
          Company’s request, you will provide it with official receipts issued by the appropriate taxing authority, or
          other such evidence that you have paid all applicable taxes. For purposes of this section, “<b>Sales Tax</b>”
          shall mean any sales or use tax and any other tax measured by sales proceeds that is the functional equivalent
          of a sales tax where the applicable taxing jurisdiction does not otherwise impose a sales or use tax.
        </p>

        <h3 className={styles.header3}>7.6. Withholding Taxes</h3>

        <p className={styles.paragraph}>
          You agree to make all payments of fees to Company free and clear of, and without reduction for, any
          withholding taxes. Any such taxes imposed on payments of fees to Company will be your sole responsibility, and
          you will provide Company with official receipts issued by the appropriate taxing authority, or such other
          evidence as we may reasonably request, to establish that such taxes have been paid.
        </p>

        <h3 className={styles.header3}>7.7. Automatic Renewal</h3>

        <p className={styles.paragraph}>
          Your subscription will continue indefinitely until terminated in accordance with the Agreement. AFTER YOUR
          INITIAL SUBSCRIPTION PERIOD, AND AGAIN AFTER ANY SUBSEQUENT SUBSCRIPTION PERIOD, YOUR SUBSCRIPTION WILL
          AUTOMATICALLY COMMENCE ON THE FIRST DAY FOLLOWING THE END OF SUCH PERIOD (EACH A “<b>RENEWAL COMMENCEMENT
          DATE</b>”) AND CONTINUE FOR AN ADDITIONAL EQUIVALENT PERIOD, AT COMPANY’S THEN-CURRENT PRICE FOR SUCH
          SUBSCRIPTION. YOU AGREE THAT YOUR ACCOUNT WILL BE SUBJECT TO THIS AUTOMATIC RENEWAL FEATURE UNLESS YOU CANCEL
          YOUR SUBSCRIPTION PRIOR TO THE RENEWAL COMMENCEMENT DATE, BY LOGGING INTO AND GOING TO THE “SUBSCRIPTION” PAGE
          OF YOUR “PROFILE” PAGE. If you want to change or terminate your subscription, please log in and go to the
          “Cancel Subscription” section on your “PROFILE” page. If you cancel your subscription, you may use your
          subscription until the end of your then-current subscription term, and your subscription will not be renewed
          after your then-current term expires. However, you will not be eligible for a prorated refund of any portion
          of the subscription fee paid for the then-current subscription period. By subscribing, you authorize Company
          to charge your Payment Provider now, and again at the beginning of any subsequent subscription period. Upon
          renewal of your subscription, if Company does not receive payment from your Payment Provider, (a) you agree to
          pay all amounts due on your Account upon demand and/or (b) you agree that Company may either terminate or
          suspend your subscription and continue to attempt to charge your Payment Provider until payment is received
          (upon receipt of payment, your Account will be activated and for purposes of automatic renewal, your new
          subscription commitment period will begin as of the day payment was received).
        </p>

        <h3 className={styles.header3}>7.8. Free Trials and Other Promotions</h3>

        <p className={styles.paragraph}>
          Any free trial or other promotion that provides Registered User level access to the Services must be used
          within the specified time of the trial. At the end of the trial period, your use of that Service will expire
          and any further use of the Service is prohibited unless you pay the applicable subscription fee. If you are
          inadvertently charged for a subscription, please contact Company to have the charges reversed.
        </p>

        <h2 className={styles.header2}>8. INDEMNIFICATION</h2>

        <p className={styles.paragraph}>
          You agree to indemnify and hold Company, its parents, subsidiaries, affiliates, officers, employees, agents,
          partners, suppliers, and licensors (each, a “<b>Company Party</b>” and collectively, the “<b>Company
          Parties</b>”) harmless from any losses, costs, liabilities and expenses (including reasonable attorneys’ fees)
          relating to or arising out of any and all of the following: (a) Your Content; (b) your use of any Service in
          violation of the Agreement; (c) your violation of any rights of another party, including any Registered Users;
          or (d) your violation of any applicable laws, rules or regulations. Company reserves the right, at its own
          cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you,
          in which event you agree to fully cooperate with Company in asserting any available defenses. This provision
          does not require you to indemnify any of the Company Parties for any unconscionable commercial practice by
          such party or for such party’s fraud, deception, false promise, misrepresentation or concealment, or
          suppression or omission of any material fact in connection with any Services provided hereunder. You agree
          that the provisions in this section will survive any termination of your Account, the Agreement and/or your
          access to the Services.
        </p>

        <h2 className={styles.header2}>9. DISCLAIMER OF WARRANTIES AND CONDITIONS</h2>

        <h3 className={styles.header3}>9.1. As Is</h3>

        <p className={styles.paragraph}>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF THE SERVICES IS
          AT YOUR SOLE RISK, AND THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS.
          COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE AND NON-INFRINGEMENT ARISING FROM USE OF THE SERVICES. COMPANY MAKES NO WARRANTY,
          REPRESENTATION OR CONDITION THAT: (1) THE SERVICES WILL MEET YOUR REQUIREMENTS; (2) YOUR USE OF THE SERVICES
          WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; OR (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE
          SERVICES WILL BE ACCURATE OR RELIABLE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
          COMPANY OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. FROM TIME TO TIME, COMPANY
          MAY OFFER NEW “BETA” FEATURES OR TOOLS WITH WHICH ITS USERS MAY EXPERIMENT. SUCH FEATURES OR TOOLS ARE OFFERED
          SOLELY FOR EXPERIMENTAL PURPOSES AND WITHOUT ANY WARRANTY OF ANY KIND, AND MAY BE MODIFIED OR DISCONTINUED AT
          COMPANY’S SOLE DISCRETION. THE PROVISIONS OF THIS SECTION APPLY WITH FULL FORCE TO SUCH FEATURES OR TOOLS.
        </p>

        <h3 className={styles.header3}>9.2. No Liability for Conduct of Third Parties</h3>

        <p className={styles.paragraph}>
          YOU ACKNOWLEDGE AND AGREE THAT COMPANY PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD COMPANY
          PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OPERATORS OF EXTERNAL SITES AND OTHER USERS OF THE
          SERVICES, AND THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU.
        </p>

        <h3 className={styles.header3}>9.3. Third-Party Materials</h3>

        <p className={styles.paragraph}>
          As a part of the Services, you may have access to materials that are hosted by another party. You agree that
          it is impossible for Company to monitor such materials and that you access these materials at your own risk.
        </p>

        <h2 className={styles.header2}>10. LIMITATION OF LIABILITY</h2>

        <h3 className={styles.header3}>10.1. Disclaimer of Certain Damages</h3>

        <p className={styles.paragraph}>
          YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT SHALL COMPANY PARTIES BE
          LIABLE FOR ANY LOSS OF PROFITS, REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, OR
          DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR USE, BUSINESS INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS
          OR SERVICES, IN EACH CASE WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING
          OUT OF OR IN CONNECTION WITH THE AGREEMENT OR ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF
          THE SERVICES, ON ANY THEORY OF LIABILITY. THE FOREGOING LIMITATION OF LIABILITY SHALL NOT APPLY TO LIABILITY
          OF A COMPANY PARTY FOR (i) DEATH OR PERSONAL INJURY CAUSED BY A COMPANY PARTY’S NEGLIGENCE; OR FOR (ii) ANY
          INJURY CAUSED BY A COMPANY PARTY’S FRAUD OR FRAUDULENT MISREPRESENTATION.
        </p>

        <h3 className={styles.header3}>10.2. Cap on Liability</h3>

        <p className={styles.paragraph}>
          TO THE FULLEST EXTENT PROVIDED BY LAW, COMPANY PARTIES WILL NOT BE LIABLE TO YOU FOR MORE THAN THE TOTAL
          AMOUNT PAID TO COMPANY BY YOU DURING THE THREE(3)-MONTH PERIOD PRIOR TO THE ACT, OMISSION OR OCCURRENCE GIVING
          RISE TO SUCH LIABILITY; OR (c) THE REMEDY OR PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIM ARISES. THE
          FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO LIABILITY OF A COMPANY PARTY FOR (i) DEATH OR PERSONAL INJURY
          CAUSED BY A COMPANY PARTY’S NEGLIGENCE; OR FOR (ii) ANY INJURY CAUSED BY A COMPANY PARTY’S FRAUD OR FRAUDULENT
          MISREPRESENTATION.
        </p>

        <h3 className={styles.header3}>10.3. User Content</h3>

        <p className={styles.paragraph}>
          EXCEPT FOR COMPANY’S OBLIGATIONS TO PROTECT YOUR PERSONAL DATA AS SET FORTH IN THE COMPANY’S PRIVACY POLICY,
          COMPANY ASSUMES NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY OR FAILURE TO STORE ANY CONTENT
          (INCLUDING, BUT NOT LIMITED TO, YOUR CONTENT), USER COMMUNICATIONS OR PERSONALIZATION SETTINGS. Exclusion of
          Damages. CERTAIN JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
          APPLY TO YOU, SOME OR ALL OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
          ADDITIONAL RIGHTS.
        </p>

        <h3 className={styles.header3}>10.4. Basis of the Bargain</h3>

        <p className={styles.paragraph}>
          THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
          COMPANY AND YOU.
        </p>

        <h2 className={styles.header2}>11. MONITORING AND ENFORCEMENT</h2>

        <h3 className={styles.header3}>11.1. </h3>

        <p className={styles.paragraph}>
          Company reserves the right to (a) take any action with respect to any of Your Content that we deem necessary
          or appropriate in our sole discretion, including if we believe that such Content violates the Agreement,
          infringes any intellectual property right or other right of any person or entity, threatens the personal
          safety of users of the Services or the public, or could create liability for Company; (b) take appropriate
          legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use
          of the Services; and/or (c) terminate or suspend your access to all or part of the Services for any or no
          reason, including without limitation, any violation of this Agreement.
        </p>

        <h3 className={styles.header3}>11.2. </h3>

        <p className={styles.paragraph}>
          If Company becomes aware of any possible violations by you of the Agreement, Company reserves the right to
          investigate such violations. If, as a result of the investigation, Company believes that criminal activity has
          occurred, Company reserves the right to refer the matter to, and to cooperate with, any and all applicable
          legal authorities. Company is entitled, except to the extent prohibited by applicable law, to disclose any
          information or materials on or in the Services, including Your Content, in Company’s possession in connection
          with your use of the Services, to (i) comply with applicable laws, legal process or governmental request; (ii)
          enforce the Agreement, (iii) respond to any claims that Your Content violates the rights of third parties,
          (iv) respond to your requests for customer service, or (v) protect the rights, property or personal safety of
          Company, its Registered Users or the public, and all enforcement or other government officials, as Company in
          its sole discretion believes to be necessary or appropriate.
        </p>

        <h2 className={styles.header2}>12. TERM AND TERMINATION</h2>

        <h3 className={styles.header3}>12.1. Term</h3>

        <p className={styles.paragraph}>
          The Agreement commences on the date when you accept them (as described in the preamble above) and remain in
          full force and effect while you use the Services, unless terminated earlier in accordance with the Agreement.
        </p>

        <h3 className={styles.header3}>12.2. Prior Use</h3>

        <p className={styles.paragraph}>
          Notwithstanding the foregoing, you hereby acknowledge and agree that the Agreement commenced on the earlier to
          occur of (a) the date you first used the Services or (b) the date you accepted the Agreement, and will remain
          in full force and effect while you use any Services, unless earlier terminated in accordance with the
          Agreement.
        </p>

        <h3 className={styles.header3}>12.3. Termination of Services by Company</h3>

        <p className={styles.paragraph}>
          The Service Subscription Fees for the Services are non-refundable. If timely payment cannot be charged to your
          Payment Provider for any reason, if you have materially breached any provision of the Agreement, or if Company
          is required to do so by law (e.g., where the provision of the Services is, or becomes, unlawful), Company has
          the right to, immediately and without notice, suspend or terminate any Services provided to you. You agree
          that all terminations for cause shall be made in Company’s sole discretion and that Company shall not be
          liable to you or any third party for any termination of your Account.
        </p>

        <h3 className={styles.header3}>12.4. Termination of Services by You</h3>

        <p className={styles.paragraph}>
          If you want to terminate the Services provided by Company, you may do so by visiting our website and choosing
          to cancel your Subscription on your profile page.
        </p>

        <h3 className={styles.header3}>12.5. Effect of Termination</h3>

        <p className={styles.paragraph}>
          Termination of any Service includes removal of access to such Service. Termination of all Services also
          includes deletion of your password and all related information, files and Content associated with or inside
          your Account (or any part thereof), including Your Content. Upon termination of any Service, your right to use
          such Service will automatically terminate immediately. You understand that any termination of Services may
          involve deletion of Your Content associated therewith from our live databases. Company will not have any
          liability whatsoever to you for any suspension or termination, including for deletion of Your Content. All
          provisions of the Agreement which by their nature should survive, shall survive termination of Services,
          including without limitation, ownership provisions, warranty disclaimers, and limitation of liability.
        </p>

        <h2 className={styles.header2}>13. INTERNATIONAL USERS</h2>

        <p className={styles.paragraph}>
          The Services can be accessed from countries around the world and may contain references to Services and
          Content that are not available in your country. These references do not imply that Company intends to announce
          such Services or Content in your country. The Services are controlled and offered by Company from its
          facilities in the United States of America. Company makes no representations that the Services are appropriate
          or available for use in other locations. Those who access or use the Services from other countries do so at
          their own volition and are responsible for compliance with local law.
        </p>

        <h2 className={styles.header2}>14. DISPUTE RESOLUTION</h2>

        <p className={styles.paragraph}>
          PLEASE READ THE FOLLOWING ARBITRATION AGREEMENT IN THIS SECTION (“<b>ARBITRATION AGREEMENT</b>”) CAREFULLY. IT
          REQUIRES USERS TO ARBITRATE DISPUTES WITH COMPANY AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
        </p>

        <h3 className={styles.header3}>14.1. Arbitration Agreement</h3>

        <p className={styles.paragraph}>
          The parties to this Agreement will submit all disputes arising under this agreement to arbitration in New York
          City, New York before a single arbitrator of the American Arbitration Association (“AAA”). The arbitrator
          shall be selected by application of the rules of the AAA, or by mutual agreement of the parties, except that
          such arbitrator shall be an attorney admitted to practice law in the State of New York. No party to this
          Agreement will challenge the jurisdiction or venue provisions as provided in this Section 14.1.
        </p>

        <h3 className={styles.header3}>14.2. Waiver of Jury Trial</h3>

        <p className={styles.paragraph}>
          YOU AND COMPANY HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT
          OF A JUDGE OR A JURY. You and Company are instead electing that all disputes, claims, or requests for relief
          shall be resolved by arbitration under this Arbitration Agreement. An arbitrator can award on an individual
          basis the same damages and relief as a court and must follow this Agreement as a court would. However, there
          is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited
          review.
        </p>

        <h3 className={styles.header3}>14.3. Waiver of Class or Other Non-Individualized Relief</h3>

        <p className={styles.paragraph}>
          ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
          ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE,
          AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER
          CUSTOMER OR USER. If a decision is issued stating that applicable law precludes enforcement of any of this
          section’s limitations as to a given dispute, claim, or request for relief, then such aspect must be severed
          from the arbitration and brought into the State or Federal Courts located in the State of New York. All other
          disputes, claims, or requests for relief shall be arbitrated.
        </p>

        <h3 className={styles.header3}>14.4. 30-Day Right to Opt Out</h3>

        <p className={styles.paragraph}>
          You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of
          your decision to opt out to: support@candle.xyz, within thirty (30) days after first becoming subject to this
          Arbitration Agreement. Your notice must include your name and address, your Company username (if any), the
          email address you used to set up your Company account (if you have one), and an unequivocal statement that you
          want to opt out of this Arbitration Agreement. If you opt out of this Arbitration Agreement, all other parts
          of this Agreement will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any
          other arbitration agreements that you may currently have, or may enter in the future, with us.
        </p>

        <h3 className={styles.header3}>14.5. Severability</h3>

        <p className={styles.paragraph}>
          Except as provided in Section 14.3 (Waiver of Class or Other Non-Individualized Relief), if any part or parts
          of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part
          or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement
          shall continue in full force and effect.
        </p>

        <h3 className={styles.header3}>14.6. Survival of Agreement</h3>

        <p className={styles.paragraph}>
          This Arbitration Agreement will survive the termination of your relationship with Company.
        </p>

        <h3 className={styles.header3}>14.7. Modification</h3>

        <p className={styles.paragraph}>
          Notwithstanding any provision in this Agreement to the contrary, we agree that if Company makes any future
          material change to this Arbitration Agreement, you may reject that change within thirty (30) days of such
          change becoming effective by writing Company at the following address: support@candle.xyz.
        </p>

        <h2 className={styles.header2}>15. THIRD-PARTY SERVICES</h2>

        <p className={styles.paragraph}>
          The Services may contain links to third-party websites, applications and advertisements for third parties
          (collectively, “<b>Third-Party Services</b>”). When you click on a link to a Third-Party Service, we will not
          warn you that you have left the Services and that you will then be subject to the terms and conditions
          (including privacy policies) of such Third-Party Service. Such Third-Party Services are not under the control
          of Company. Company is not responsible for any Third-Party Services. Company provides these Third-Party
          Services only as a convenience and does not review, approve, monitor, endorse, warrant, or make any
          representations with respect to Third-Party Services, or any product or service provided in connection
          therewith. You use all links in Third-Party Services at your own risk. When you leave our Website, the
          Agreement and our policies no longer govern. You should review applicable terms and policies, including
          privacy and data gathering practices, of any Third-Party Services, and make whatever investigation you feel
          necessary or appropriate before proceeding with any transaction with any third party.
        </p>

        <h2 className={styles.header2}>16. GENERAL PROVISIONS</h2>

        <h3 className={styles.header3}>16.1.</h3>

        <p className={styles.paragraph}>
          ANY DISPUTE, CLAIM OR REQUEST FOR RELIEF RELATING IN ANY WAY TO YOUR USE OF THE SERVICES WILL BE GOVERNED AND
          INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF NEW YORK, CONSISTENT WITH THE FEDERAL ARBITRATION ACT,
          WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANY OTHER JURISDICTION.
          THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS IS EXPRESSLY EXCLUDED FROM THIS
          AGREEMENT.
        </p>

        <h3 className={styles.header3}>16.2. Compliance with Laws</h3>

        <p className={styles.paragraph}>
          You agree to comply with all applicable laws regarding your use of the Services.
        </p>

        <h3 className={styles.header3}>16.3. Exclusive Venue</h3>

        <p className={styles.paragraph}>
          To the extent the parties are permitted under this Agreement to initiate litigation in a court, both you and
          Company agree that all claims and disputes arising out of or relating to the Agreement will be litigated
          exclusively in the state or federal courts located in New York County, New York.
        </p>

        <h3 className={styles.header3}>16.4. Electronic Communications</h3>

        <p className={styles.paragraph}>
          The communications between you and Company may take place via electronic means, whether you visit the Services
          or send Company e-mails, or whether Company posts notices on the Services or communicates with you via e-mail.
          For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and
          (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that
          Company provides to you electronically satisfy any legal requirement that such communications would satisfy if
          it were to be in writing. The foregoing does not affect your statutory rights, including but not limited to
          the Electronic Signatures in Global and National Commerce Act at 15 U.S.C. §7001 et seq.
        </p>

        <h3 className={styles.header3}>16.5. Assignment</h3>

        <p className={styles.paragraph}>
          The Agreement, and your rights and obligations hereunder, may not be assigned, subcontracted, delegated or
          otherwise transferred by you without Company’s prior written consent, and any attempted assignment,
          subcontract, delegation, or transfer in violation of the foregoing will be null and void.
        </p>

        <h3 className={styles.header3}>16.6. Relationship of the Parties</h3>

        <p className={styles.paragraph}>
          Nothing contained in this Agreement or your use of the Services shall be construed to constitute either party
          as a partner, joint venturer, employee or agent of the other party, nor shall either party hold itself out as
          such. Neither party has any right or authority to incur, assume or create, in writing or otherwise, any
          warranty, liability or other obligation of any kind, express or implied, in the name of or on behalf of the
          other party, it being intended by both parties that each shall remain independent contractors responsible for
          its own actions.
        </p>

        <h3 className={styles.header3}>16.7. Force Majeure</h3>

        <p className={styles.paragraph}>
          Company shall not be liable for any delay or failure to perform resulting from causes outside its reasonable
          control, including, but not limited to, acts of God, epidemics, war, terrorism, riots, embargos, acts of civil
          or military authorities, fire, floods, accidents, strikes or shortages of transportation facilities, fuel,
          energy, labor or materials.
        </p>

        <h3 className={styles.header3}>16.8. Questions, Complaints, Claims</h3>

        <p className={styles.paragraph}>
          If you have any questions, complaints or claims with respect to the Services, please contact us at:
          support@candle.xyz. We will do our best to address your concerns. If you feel that your concerns have been
          addressed incompletely, we invite you to let us know for further investigation.
        </p>

        <h3 className={styles.header3}>16.9. Choice of Language</h3>

        <p className={styles.paragraph}>
          It is the express wish of the parties that the Agreement and all related documents have been drawn up in
          English.
        </p>

        <h3 className={styles.header3}>16.10. Notice</h3>

        <p className={styles.paragraph}>
          Where Company requires that you provide an e-mail address, you are responsible for providing Company with your
          most current e-mail address. In the event that the last e-mail address you provided to Company is not valid,
          or for any reason is not capable of delivering to you any notices required/ permitted by the Agreement,
          Company’s dispatch of the e-mail containing such notice will nonetheless constitute effective notice.
        </p>

        <h3 className={styles.header3}>16.11. Waiver</h3>

        <p className={styles.paragraph}>
          Any waiver or failure to enforce any provision of the Agreement on one occasion will not be deemed a waiver of
          any other provision or of such provision on any other occasion.
        </p>

        <h3 className={styles.header3}>16.12. Severability</h3>

        <p className={styles.paragraph}>
          If any portion of the Agreement is held invalid or unenforceable, that portion shall be construed in a manner
          to reflect, as nearly as possible, the original intention of the parties, and the remaining portions shall
          remain in full force and effect.
        </p>

        <h3 className={styles.header3}>16.13. Consumer Complaints</h3>

        <p className={styles.paragraph}>
          In accordance with California Civil Code §1789.3, you may report complaints to the Complaint Assistance Unit
          of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in
          writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952-5210.
        </p>

        <h3 className={styles.header3}>16.14. Entire Agreement</h3>

        <p className={styles.paragraph}>
          The Agreement is the final, complete and exclusive agreement of the parties with respect to the subject matter
          hereof and supersedes and merges all prior discussions between the parties with respect to such subject
          matter.
        </p>
      </div>
  );
}

export default TermsPage;
