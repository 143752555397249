import styles from "./TopBar.module.scss";
import candle from "../candle.png";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";

// If currently running on localhost, use a likely localhost url for links to main app.
const rootUrl = window.location.href.startsWith("http://localhost") ? "http://localhost:3000" : "https://app.candle.xyz";
export const loginUrl = rootUrl;
export const signupUrl = `${rootUrl}/signup`;

export function TopBar() {

  return (
      <>
        <header className={`${styles.topBar} d-flex align-items-center`}>
          <img className={styles.logo} src={candle} alt=""/>
          <div><Link to="/" className={styles.homeLink}>CandleX</Link></div>
          <div className="flex-grow-1"/>
          <Button variant="contained" className={`candle-highlight ${styles.topBarSignup}`} href={signupUrl}>Sign
            up</Button>
        </header>
        <div className={styles.topBarReserve}/>
        <div className={styles.actions}>
          <Button variant="text" className={styles.actionButton} href={loginUrl}>Log in</Button>
          <Button variant="text" className={styles.actionButton} component={Link} to="/about">About</Button>
          <Button variant="text" className={styles.actionButton} component={Link} to="/pricing">Pricing</Button>
          <Button variant="text" className={styles.actionButton} component={Link} to="/faq">FAQ</Button>
        </div>
      </>
  );
}
