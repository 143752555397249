import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Container} from "@material-ui/core";
import PrivacyPage from "../privacy/PrivacyPage";
import TermsPage from "../terms/TermsPage";
import UnsubscribePage from "../unsubscribe/UnsubscribePage";
import HomePage from "../home/HomePage";
import FAQPage from "../faq/FAQPage";
import AboutPage from "../about/AboutPage";
import {PricingPage} from "../pricing/PricingPage";


function App() {
  return (
      <Router>
        <Container>
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/faq" element={<FAQPage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/pricing" element={<PricingPage/>}/>
            <Route path="/privacy-policy" element={<PrivacyPage/>}/>
            <Route path="/terms-of-service" element={<TermsPage/>}/>
            <Route path="/unsubscribe/:token" element={<UnsubscribePage/>}/>
          </Routes>
        </Container>
      </Router>
  );
}

export default App;
