import React, {useEffect, useState} from "react";
import axios from "axios";
import styles from "./UnsubscribePage.module.scss";
import {Button, CircularProgress} from "@material-ui/core";
import {useParams} from "react-router-dom";

/**
 * Allows the user to unsubscribe to marketing emails without being logged in. Users can also 'resubsribe' if they want.
 */
function UnsubscribePage() {
  const [formDisabled, setFormDisabled] = useState(false);
  const [requestError, setFormRequestError] = useState(false);

  const UNKNOWN = 'UNKNOWN';
  const SUBSCRIBED = 'SUBSCRIBED';
  const UNSUBSCRIBED = 'UNSUBSCRIBED';
  const [status, setStatus] = useState(UNKNOWN);

  let {token} = useParams<{ token: string }>();

  useEffect(() => {
    if (token === undefined) {
      return;
    }
    loadPage(token);
  }, [token]);

  async function loadPage(token: string) {
    setFormDisabled(true);
    setFormRequestError(false);
    try {
      const result = await axios.get(`/api/manageCampaignPage?token=${token}`);
      setStatus(result.data.subscribed ? SUBSCRIBED : UNSUBSCRIBED);
    } catch (e) {
      setFormRequestError(true);
      console.log(`manageCampaignPage Error: ${e}`);
    } finally {
      setFormDisabled(false);
    }
  }

  async function manage(subscribe: boolean) {
    setFormDisabled(true);
    setFormRequestError(false);
    try {
      await axios.post('/api/manageCampaign', {
        token: token,
        subscribe: subscribe,
      });
      setStatus(subscribe ? SUBSCRIBED : UNSUBSCRIBED);
    } catch (e) {
      console.log(`manageCampaign error: ${e}`);
      setFormRequestError(true);
    } finally {
      setFormDisabled(false);
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (formDisabled) {
      return;
    }
    manage(status === UNSUBSCRIBED);
  }

  return (
      <div className={styles.center}>
        <div className={styles.content}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            {status === SUBSCRIBED &&
                <div className={styles.confirmQuestion}>
                  Do you wish to remove yourself from our opt-in messaging?
                </div>
            }
            {status === UNSUBSCRIBED &&
                <div className={styles.optedOutText}>
                  You have opted-out.
                </div>
            }
            {status !== UNKNOWN &&
                <Button variant="contained" color="secondary" type="submit" disabled={formDisabled}>
                  {status === SUBSCRIBED ? 'Yes, unsubscribe' : 'Re-subscribe'}
                </Button>
            }
          </form>

          {((status === UNKNOWN && !requestError) || formDisabled) &&
              <CircularProgress className={styles.unsubscribeSpinner} color="secondary" size={24}/>}

          {requestError && <div className="text-danger">An unknown error has happened</div>}
        </div>
      </div>
  );
}

export default UnsubscribePage;
