import React from "react";
import styles from "./HomePage.module.scss";
import {Button} from "@material-ui/core";
import {signupUrl, TopBar} from "../topbar/TopBar";
import Footer from "./Footer";

function HomePage() {
  // Points to the cloudfront distribution for static resources.
  const root = 'https://d18r9hnfotn1st.cloudfront.net/www/screenshots';

  return (
      <div className={styles.root}>
        <TopBar/>
        <div className={styles.topGrid}>
          <div className={styles.tagLine}>
            Trading made <span className="candle-text-highlight">strategic & fun</span>
          </div>

          <div className={styles.intro}>
            <span className="candle-text-highlight">Automated trading for the masses</span> because who has time to
            watch the market.
          </div>

          <div className={styles.signupButtonContainer}>
            <Button variant="contained" className={`candle-highlight ${styles.middleSignupButton}`} href={signupUrl}
                size="large">Sign up for free</Button>
          </div>

          <div className={styles.intro}>
            Explore simple and well known strategies, backtest their performance, and begin automated trading within
            minutes.
          </div>
        </div>

        <div className={styles.screenshotContainer}>
          <img src={`${root}/landing-mobile-2.png`} className={styles.screenshot}
              alt="Screenshot showing performance graph and per stock profit/loss"/>

          <div className={styles.screenshotText}>Understand your <span
              className="candle-text-highlight">performance at a glance</span>
          </div>

          <div className={styles.issueItem}>
            <div className={styles.fix}>Stock P&L per time span, vs. buy & hold</div>
            <div className={styles.issue}>😵 &nbsp;How did I do?</div>
          </div>
        </div>

        <div className={`${styles.screenshotContainer} ${styles.screenshotContainerFlip}`}>
          <img src={`${root}/recent-trades-mobile.jpg`} className={styles.screenshot}
              alt="Screenshot of recent trade activity"/>

          <div className={styles.screenshotText}>Trades happen <span
              className="candle-text-highlight">without bothering you</span>
          </div>

          <div className={styles.issueItem}>
            <div className={styles.fix}>No more greed or fear</div>
            <div className={styles.issue}>😱 &nbsp;Would I miss out or would I lose?</div>
          </div>
        </div>

        <div className={styles.screenshotContainer}>
          <img src={`${root}/strategy-page-mobile.jpg`} className={styles.screenshot}
              alt="Screenshot of the strategy page"/>

          <div className={styles.screenshotText}>Track live performance <span className="candle-text-highlight">per strategy</span>
          </div>

          <div className={styles.issueItem}>
            <div className={styles.fix}>Tireless monitor and instant trade</div>
            <div className={styles.issue}>😣 &nbsp;Don't have time to follow market</div>
          </div>
        </div>

        <div className={`${styles.screenshotContainer} ${styles.screenshotContainerFlip}`}>
          <img src={`${root}/create-mobile2.jpg`} className={styles.screenshot}
              alt="Screenshot of the create strategy page"/>

          <div className={styles.screenshotText}>Explore strategies <span
              className="candle-text-highlight">quickly</span>
          </div>

          <div className={styles.issueItem}>
            <div className={styles.fix}>Guided UI, translate intuition to backtest</div>
            <div className={styles.issue}>🤔 &nbsp;I'm not a mathematician or programmer</div>
          </div>
        </div>

        <div className={styles.screenshotContainer}>
          <img src={`${root}/backtest-mobile.jpg`} className={styles.screenshot}
              alt="Screenshot of the backtest results dialog"/>

          <div className={styles.screenshotText}>Validate how strategies <span className="candle-text-highlight">would have</span> performed
          </div>

          <div className={styles.issueItem}>
            <div className={styles.fix}>See past performance metrics</div>
            <div className={styles.issue}>🧐 &nbsp;Did this strategy work in the past?</div>
          </div>
        </div>

        <div className={`${styles.screenshotContainer} ${styles.screenshotContainerFlip}`}>
          <img src={`${root}/discover-mobile.jpg`} className={styles.screenshot}
              alt="Screenshot of the discover"/>

          <div className={styles.screenshotText}>Discover <span className="candle-text-highlight">auto tuned</span> strategies
          </div>

          <div className={styles.issueItem}>
            <div className={styles.fix}>Find high performing strategies quickly</div>
            <div className={styles.issue}>🤩 &nbsp;Are there any good ones at all?</div>
          </div>
        </div>

        <div className={styles.screenshotContainer}>
          <img src={`${root}/rebalance-mobile.jpg`} className={styles.screenshot}
              alt="Screenshot of the rebalance page"/>

          <div className={styles.screenshotText}>Manage by <span className="candle-text-highlight">performance</span>
          </div>

          <div className={styles.issueItem}>
            <div className={styles.fix}>Rebalance portfolio based on $$$</div>
            <div className={styles.issue}>💸 &nbsp;Where to put more money in?</div>
          </div>
        </div>

        <div className={`${styles.screenshotContainer} ${styles.screenshotContainerFlip}`}>
          <img src={`${root}/switch-strategy-mobile2.jpg`} className={styles.screenshot}
              alt="Screenshot of the switch strategy page"/>

          <div className={styles.screenshotText}>React to market <span
              className="candle-text-highlight">after hours</span>
          </div>

          <div className={styles.issueItem}>
            <div className={styles.fix}>End or switch strategies seamlessly</div>
            <div className={styles.issue}>🙃 &nbsp;But market changes all the time</div>
          </div>
        </div>

        <div className={styles.screenshotContainer}>
          <img src={`${root}/paper-mobile.jpg`} className={styles.screenshot}
              alt="Screenshot of paper trading profile page"/>

          <div className={styles.screenshotText}>Try us out using <span className="candle-text-highlight">paper money</span>
          </div>

          <div className={styles.issueItem}>
            <div className={styles.fix}>Try out strategies in paper trading</div>
            <div className={styles.issue}>😧 &nbsp;Not ready to automate my money yet</div>
          </div>
        </div>
        <Footer/>
      </div>

  );
}

export default HomePage;
