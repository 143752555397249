import React from "react";
import styles from "./AboutPage.module.scss";
import {signupUrl, TopBar} from "../topbar/TopBar";
import Footer from "../home/Footer";
import {Button, Divider} from "@material-ui/core";

function AboutPage() {
  const root = 'https://d18r9hnfotn1st.cloudfront.net/www/about';
  return (
      <div className={styles.root}>
        <TopBar/>

        <div className={styles.topGrid}>
          <div className={styles.tagLine}>
            <span>Helping retail investors grow wealth</span>
          </div>

          <div className={styles.intro}>
            <p>
              For retail investors who want <span className="candle-text-highlight">reduced portfolio volatility </span>
              and <span className="candle-text-highlight">increased returns</span>, CandleX is
              an automated trading platform that makes trading strategic and fun.
            </p>
            <p className="pt-4">
              CandleX is a platform for change. Our mission towards low effort automated trading breaks down
              professional barriers - empowering <span className="candle-text-highlight">informed decisions</span> and
              inspiring <span className="candle-text-highlight">productive change</span> in retail trading.
            </p>
            <div className={styles.signupButtonContainer}>
              <Button variant="contained" className={`candle-highlight ${styles.middleSignupButton}`}
                  href={signupUrl}
                  size="large">Sign up for free</Button>
            </div>
          </div>

          <div className={styles.tagImageContainer}>
            <img src={`${root}/human.png`} className={styles.tagImage} alt="Human trading"/>
          </div>
        </div>

        <div className="d-flex align-items-center mt-5 flex-column">
          <div className={styles.problem}>
            For majority of retail investors, it’s extremely difficult to monitor and react to market changes quickly.
            Without actively reacting, they can find their unrealized gains disappear, or go deeply underwater before
            they notice.
          </div>
          <img src={`${root}/stress.png`} className={styles.stressImage} alt="Underwater"/>
        </div>

        <div className={styles.whatIf}>
          What if?
        </div>
        <div className={styles.subContainer}>
          <div className={`${styles.idea} ${styles.ideaOrange}`}>
            There was a platform that tirelessly monitored the market
          </div>
          <div className={`${styles.idea} ${styles.ideaYellow}`}>
            We replace the emotional rollercoaster ride with rules and logic
          </div>
          <div className={`${styles.idea} ${styles.ideaGreen}`}>
            Investors are empowered to validate their intuitions
          </div>
          <div className={`${styles.idea} ${styles.ideaTeal}`}>
            Technology is used to enable a diversity of strategies
          </div>
          <div className={`${styles.idea} ${styles.ideaBlue}`}>
            A simple and intuitive tool could show investors their performance
          </div>
          <div className={`${styles.idea} ${styles.ideaDarkBlue}`}>
            One could easily replicate and acknowledge good trades
          </div>
        </div>

        <div className={styles.productImageContainer}>
          <img src={`${root}/colored_product.png`} className={styles.productImage} alt="CandleX product"/>
        </div>

        <Divider className="mt-5 mb-5"/>
        <Footer/>
      </div>
  );
}

export default AboutPage;
